import React, { ReactNode } from "react";

import { classNames } from "helper/style";

import BaseButton, { IBaseButtonProps } from "./BaseButton";

export interface ISecondaryButtonProps extends IBaseButtonProps {
  icon?: ReactNode;
  iconClassName?: string;
}

const SecondaryButton: React.FunctionComponent<ISecondaryButtonProps> = ({
  children,
  // onClick,
  // disabled,
  className,
  icon,
  iconClassName,
  ...props
}) => {
  return (
    <BaseButton 
      className={classNames(
        "btn btn-fill btn-secondary",
        className || "",
      )} 
      {...props}
    >
      {children}
    </BaseButton>
  )
};

export default SecondaryButton;
