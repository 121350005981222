interface Option {
  name: string;
  value: string;
  label: string;
}

export const deadlineOptions: Array<Option> = [
  { name: "deadline", value: "1", label: "1 Day" },
  { name: "deadline", value: "2", label: "2 Days" },
  { name: "deadline", value: "3", label: "3 Days" },
  { name: "deadline", value: "4", label: "4 Days" },
];