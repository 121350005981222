import React from "react";

import { IBaseInputProps } from "./BaseInputV2";

interface IBaseFileInputProps extends IBaseInputProps {
}

const BaseFileInput: React.FunctionComponent<IBaseFileInputProps> = ({
  name = 'file',
  type,
  className,
  children,
  ...props
}) => {
  return (
    <label
      className="w-full"
      htmlFor={`${name}-upload`}
    >
      {children}
      <input 
        id={`${name}-upload`}
        name={`${name}-upload`}
        type={type || "file"} 
        className={`sr-only w-full ${className}`} 
        {...props}
      />
  </label>
  );
};

export default BaseFileInput;
